import { FormItemProps, SharedProps } from "@components/FormItem/formItemInterfaces";
import { FormItemOption } from "@components/FormItem/optionFactory";

export const FORM_ITEM_EVENT_INPUT = "update:modelValue";
export const FORM_ITEM_EVENT_UPDATE = "update";
export const FORM_ITEM_EVENT_CHANGE = "onChange";

const selectOnlyProps = {
  optionLabel: {
    type: String,
    required: false,
    default: "label",
  },
  optionValue: {
    type: String,
    required: false,
    default: "value",
  },
};

const radioCheckboxOnlyProps = {
  /**
   * @values row, column
   */
  direction: {
    type: String,
    required: false,
    default: "row",
  },
  optionValue: {
    type: String,
    required: false,
    default: "value",
  },
};

const maskOnlyProps = {
  mask: {
    type: String,
    required: false,
    default: "", // row or column
  },
};

const verticalsOnlyProps = {
  /**
   * Tamanho da grid dividido em breakpoints na ordem `sm md lg` com os valores padroes `2 3 5`
   */
  gridSize: {
    type: String,
    required: false,
    default: "2 3 5",
  },
  /**
   * mode de exibicao das verticais: `compact` ou `default`
   */
  mode: {
    type: String,
    required: false,
    default: "default",
  },
};

const sharedProps = {
  value: {
    type: String,
    required: false,
    default: "",
  },

  /**
   * @values text, password, mask, select, date, radio, checkbox, vertical
   */
  type: {
    type: String,
    required: true,
    default: "text",
  },
  classes: {
    type: Array,
    required: false,
    default: () => [],
  },
  name: {
    type: String,
    required: true,
    default: "",
  },

  /**
   * caso nao passar o id via prop vai usar o `name`
   */
  id: {
    type: String,
    required: false,
    default: undefined,
  },
  placeholder: {
    type: String,
    required: false,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },

  /**
   * @property {FormItemOption[]} options array com opcoes para ser usado em selects, radios e checkboxes
   * @see FormItemOption
   */
  options: {
    type: Array<any>,
    required: false,
    default: () => [],
  },

  /**
   * Texto adicional para mensagens e dicas.
   * IMPORTANTE: se tiver um `validator` e ocasionar um erro esse texto sera sobrescrito
   */
  message: {
    type: String,
    required: false,
    default: undefined,
  },

  /**
   * A particula inicial `uil` é adicionada automaticamente
   * OBS: Por enquanto so funciona nos inputs do tipo texto, especificamente no `InputText`
   */
  iconLeft: {
    type: String,
    required: false,
    default: undefined,
  },

  /**
   * A particula inicial `uil` é adicionada automaticamente
   * OBS: Por enquanto so funciona nos inputs do tipo texto, especificamente no `InputText`
   */
  iconRight: {
    type: String,
    required: false,
    default: undefined,
  },
  ...selectOnlyProps,
  ...radioCheckboxOnlyProps,
  ...maskOnlyProps,
  ...verticalsOnlyProps,
};

export const formItemProps = () => ({
  ...sharedProps,
  label: {
    type: String,
    required: false,
    default: "",
  },
  /** optional classes para serem passadas ao label */
  labelStyle: {
    type: String,
    required: false,
    default: "",
  },
  /** optional oculta/remove o label do dom/html */
  labelHide: {
    type: Boolean,
    required: false,
    default: false,
  },
  /** optional */
  required: {
    type: Boolean,
    required: false,
    default: true,
  },
  /** optional */
  validator: {
    type: Function,
    required: false,
    default: null,
  },
});

export const formRenderProps = () => sharedProps;

export const formRenderEvents = [FORM_ITEM_EVENT_UPDATE];
export const formRenderEmits = (emiter) => ({
  update: (value: any) => emiter(FORM_ITEM_EVENT_UPDATE, value),
});

export const formItemEvents = [FORM_ITEM_EVENT_INPUT, FORM_ITEM_EVENT_CHANGE];
export const formItemEmits = (emiter) => ({
  update: (value: any) => emiter(FORM_ITEM_EVENT_INPUT, value),
  change: (value: any) => emiter(FORM_ITEM_EVENT_CHANGE, value),
});

export const formItemPropsDefaults: FormItemProps = {
  type: "text",
  name: "",
  value: "",
  // label: "",
  mask: "",
  optionLabel: "label",
  optionValue: "value",
  labelStyle: "",
  direction: "row",
  gridSize: "2 3 5",
  options: [],
  labelHide: false,
  disabled: false,
  required: true,
  id: undefined,
  placeholder: undefined,
  message: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  validator: undefined,
};
