import { FormValidatorFunction } from "@/interfaces/validation";

const validateEmail: FormValidatorFunction<string> = (v = "") => {
  if (v.indexOf("@") !== -1) {
    return;
  }

  return "E-mail inválido";
};

export default validateEmail;
