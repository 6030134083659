<template>
  <div :id="props.id" class="p-inputwrapper-filled relative w-full flex" :class="classes.wrapper">
    <div v-for="(option, index) in props.options" :key="index" :class="classes.container">
      <Checkbox
        v-model="mutableModel"
        :inputId="`${props.id}-${index}`"
        :name="props.name"
        :value="option.value"
        @update:model-value="emit.update"
      />
      <label :for="`${props.id}-${index}`" class="floatlabel-exclude ml-2">{{ option.label }}</label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { formItemEmits, formRenderEmits, formRenderEvents, formRenderProps } from "@components/FormItem/formItemHelper";
import { computed, defineEmits, ref } from "vue";

const emiter = defineEmits(formRenderEvents);
const emit = formRenderEmits(emiter);
const props = defineProps(formRenderProps());
const mutableModel = ref(props.value);
const isRow = props.direction === "row";
const isColumn = props.direction === "column";

const classes = computed(() => ({
  wrapper: {
    "flex-row": isRow,
    "flex-col": isColumn,
  },
  container: {
    "flex": isRow,
    "mr-4": isRow,
    "mb-2": isColumn,
  },
}));
</script>

<style scoped></style>
