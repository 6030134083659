<template>
  <Dropdown
    :id="props.id"
    v-model="mutableModel"
    class="w-full"
    appendTo="self"
    :inputId="props.id"
    :placeholder="props.placeholder"
    :options="props.options"
    :optionLabel="props.optionLabel"
    :optionValue="props.optionValue"
    :class="props.classes"
  />
</template>

<script setup lang="ts">
import { formItemEmits, formRenderEmits, formRenderEvents, formRenderProps } from "@components/FormItem/formItemHelper";
import { defineEmits, ref, watch } from "vue";

const emiter = defineEmits(formRenderEvents);
const emit = formRenderEmits(emiter);

const props = defineProps(formRenderProps());
const mutableModel = ref(props.value);

watch(() => mutableModel.value, emit.update);
</script>

<style lang="scss" scoped></style>
