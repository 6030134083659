<template>
  <div class="form-item-container" :class="{ 'pt-2': props.labelHide, 'pt-8': !props.labelHide }">
    <span
      class="form-field-container w-full"
      :class="{
        'p-float-label': !props.labelHide,
        'p-input-icon-left': props.iconLeft,
        'p-input-icon-right': props.iconRight,
      }"
    >
      <i v-if="props.iconLeft" :class="['uil', props.iconLeft]" />
      <RenderComp v-bind="props" :id="fieldId" :classes="[errorClass]" @update="handleFieldUpdate" />
      <i v-if="props.iconRight" :class="['uil', props.iconRight]" />

      <label
        v-if="!props.labelHide"
        class="form-field-label"
        :class="[`form-field-label_${props.type}`, props.labelStyle]"
        :for="fieldId"
      >
        {{ fullLabel }}
      </label>
    </span>
    <small class="form-field-message" :class="[errorClass]">{{ message }} &nbsp;</small>
  </div>
</template>

<script setup lang="ts">
import { formItemEmits, formItemEvents } from "@components/FormItem/formItemHelper";
import { FormItemProps } from "@components/FormItem/formItemInterfaces";
import CheckboxRender from "@components/FormItem/renderers/CheckboxRender.vue";
import DateRender from "@components/FormItem/renderers/DateRender.vue";
import MaskRender from "@components/FormItem/renderers/MaskRender.vue";
import RadioRender from "@components/FormItem/renderers/RadioRender.vue";
import SelectRender from "@components/FormItem/renderers/SelectRender.vue";
import TextFieldRender from "@components/FormItem/renderers/TextFieldRender.vue";
import VerticalRender from "@components/FormItem/renderers/VerticalRender.vue";
import { computed, defineEmits, defineProps, onMounted, ref } from "vue";

const props = defineProps<FormItemProps>();
const emiter = defineEmits(formItemEvents);
const emit = formItemEmits(emiter);

const mutableModel = ref(props.value);

const errorClass = ref("");
const message = ref(props.message);
const requiredMark = computed(() => (props.validator || props.required === true ? "*" : ""));
const RenderComp = computed(() => typesToRenderMap[props.type]);
const fieldId = computed(() => props.id ?? props.name);
const fullLabel = computed(() => {
  if (!props.label) {
    return "";
  }
  return `${props.label}${requiredMark.value}`;
});

const typesToRenderMap = {
  text: TextFieldRender,
  password: TextFieldRender,
  mask: MaskRender,
  select: SelectRender,
  date: DateRender,
  radio: RadioRender,
  checkbox: CheckboxRender,
  vertical: VerticalRender,
};

const validateField = async (v) => {
  if (!props.validator) {
    return;
  }

  const errorMessage = await props.validator(v);
  const isInvalid = typeof errorMessage === "string";

  errorClass.value = isInvalid ? "p-invalid" : "p-valid";
  message.value = errorMessage;
};

const handleFieldUpdate = async (value) => {
  await validateField(value);
  emit.update(value);
  emit.change(value);
};

onMounted(() => {
  // validateField(props.value);
});
</script>

<style lang="scss" scoped>
@import "@/styles/themes/tailwind/tailwind-light/_variables";

.form-item-container .form-field-label {
  color: $shade500;
}
.form-item-container .form-field-message.p-invalid {
  color: $dangerColor;
}
</style>
